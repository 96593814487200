import axios from 'axios'
import {authHeader} from '../../middleware/auth-header'
import store from '../../store'
import API_URL from '../../constants'

export const state = {
  queuesLoading: true,
  templateQueuesLoading: true,
  allQueues: [{}],
  queues: [{}],
  selectedQueue: "",
}

export const mutations = {
  //cannot do async in mutations.
  populateQueues(state, queues){
    state.allQueues = queues
    state.queues = queues
  },
  filterQueues(state, queues){
    state.queues = queues
  },
  loaded(state){
    state.queuesLoading = false
  },
  load(state){
    state.queuesLoading = true;
  },
  loadQueueForTemplate(state){
    state.templateQueuesLoading = true
  },
  loadedQueueForTemplate(state){
    state.templateQueuesLoading = false    
  }      
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async loadQueues({commit, dispatch, state}){
    commit("load")
    try {
      let projectData = JSON.parse(JSON.stringify(store.getters.getProjectsForWorkflows()))
      if(projectData==null){
        await dispatch('loadProjects')
        projectData = JSON.parse(JSON.stringify(store.getters.getProjectsForWorkflows()))
      }
      let projects = projectData.filter(p => p.active).map(p => p.name)
      let queues = state.queues.filter(q => projects.includes(q.project))
      for(var i = 0; i < projects.length; i++){
        if(!queues.map(q => q.project).includes(projects[i])){
          let res = await axios.get(API_URL + `/queues/${projects[i]}`, await authHeader())
          if(res.data.length > 0){
            for(var j = 0; j < res.data.length; j++){
              queues.push(res.data[j])
            }
          }
        }        
      }
      commit('populateQueues', queues)
      commit('loaded')
    } catch (e) {
      dispatch('notify', {message:"Error Loading Queues: " + e.message, status:false})
      commit('loaded')      
    }    
  },
  async loadSimpleQueues({commit}, project){
    commit("load")
    try{
      let queues = []
      let res = await axios.get(API_URL + `/queues/${project}`, await authHeader())
      if(res.data.length > 0){
        for(var j = 0; j < res.data.length; j++){
          queues.push(res.data[j])
        }
      }
      commit('populateQueues', queues)
    }
    catch(e){}
    commit('loaded')

  },
  async loadQueuesForTemplates({commit}, project){
    try{
      commit("loadQueueForTemplate")
      let proj = project
      let res = await axios.get(API_URL + `/queues/${proj}`, await authHeader())
      commit('populateQueues', res.data)
    }
    catch(e){
      console.log(e)
    }
    commit('loadedQueueForTemplate')      
  },
  async filterQueues({commit, state}, project){
    try{
      commit("load")
      let queues = state.queues.filter(queue => queue.project !== project)
      commit('filterQueues', queues)
      commit('loaded')
    }
    catch (e) {
      dispatch('notify', {message:"Error Filtering Queues: " + e.message, status:false})
    }    
  }   
}

export const getters = {
  getQueues: state => state.queues,
  getSelectedQueue: state => state.selectedQueue,
  queuesLoading: state => state.queuesLoading,
  templateQueuesLoading: state => state.templateQueuesLoading
}