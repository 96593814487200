import axios from "axios";
import { authHeader } from "../../middleware/auth-header";
import router from "../../router";
import API_URL from "../../constants";

export const state = {
  templateLoading: true,
  template: {},
};

export const mutations = {
  //cannot do async in mutations.
  populateTemplate(state, template) {
    state.template = template;
  },
  loaded(state) {
    state.templateLoading = false;
  },
  load(state) {
    state.templateLoading = true;
  },
};

export const actions = {
  async loadTemplate({ commit, dispatch }, templateDetails) {
    commit("load");
    commit("populateTemplate", {});
    try {
      const source = templateDetails.source;
      if (source !== "shared") {
        dispatch("selectProject", source);
      } else {
        dispatch("selectProject", null);
      }
      const name = templateDetails.template_name;
      const res = await axios.get(`${API_URL}/templates/${source}/${name}`, await authHeader());
      res.data.params.sort((a, b) => {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      res.data.params.forEach(
        (param) => (param.value = param.default !== null ? param.default : null)
      );

      commit("populateTemplate", res.data);
    } catch (e) {
      dispatch("notify", { message: "Error Loading Templates: " + e.message, status: false });
    }
    commit("loaded");
  },
  async loadTemplateForWorkflow({ commit, state, dispatch }, workflowDetails) {
    commit("load");
    try {
      const projectsRes = await axios.get(API_URL + "/projects", await authHeader());
      let templateName = workflowDetails.templateName
      let templateFound=false
      let foundTemplate = {}
      try{
        // first try to see if it's a shared template. much faster that way. 
        console.log(templateName)
        const shared_res = await axios.get(`${API_URL}/templates/shared/${templateName}`, await authHeader());
        if(shared_res.data){
          foundTemplate = shared_res.data
          templateFound = true
        }
      }
      catch(e){
        console.log('not shared so trying projects')
      }
      if(!templateFound){
        const filteredProjects = projectsRes.data.filter((project) => project !== "project-name");
        for (var i = 0; i < filteredProjects.length; i++) {
          let res = await axios.get(`${API_URL}/templates/${filteredProjects[i]}`,await authHeader());
          if (res.data.length > 0 && res.data.map((template) => template.name).includes(templateName)) {
            templateFound = true;
            const template_res = await axios.get(`${API_URL}/templates/${filteredProjects[i]}/${templateName}`, await authHeader());
            foundTemplate = template_res.data
            break;
          }        
        }        
      }
      if(!templateFound){
        commit('populateTemplate', {})
        dispatch("notify", { message: templateName + ' not found', status: false });
      }  
      else{
        foundTemplate.params.sort((a, b) => {
          var nameA = a.name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        foundTemplate.params.forEach(
          (param) => (param.value = param.default !== null ? param.default : null)
        );
        commit("populateTemplate", foundTemplate);
      }
      commit("loaded");
    } catch (e) {
      dispatch("notify", { message: e.message, status: false });
      commit("loaded");
    }
  },
  async createWorkflowFromTemplate({ commit, state, dispatch }, workflowTemplateDetails) {
    commit("load");
    try {
      const data = state.template;
      data.template_source = state.template.source;
      data.template_name = state.template.name;
      data.workflow_iteration = "1";
      data.project = workflowTemplateDetails.project;
      // set the iteration value to 1 for iteration
      state.template.params.push({ name: "iteration", value: 1 });
      state.template.params
        .filter((p) => p.type === "list")
        .forEach((p) => (p.value = p.viewValue.split("\n")));
      state.template.params
        .filter((p) => p.value === undefined)
        .forEach((p) => (p.value = p.default));
      if (data.description === null) {
        data.description = data.name;
      }
      const res = await axios.post(`${API_URL}/create_workflow`, data, await authHeader());
      dispatch("notify", {
        message: "Workflow has been created: " + res.data.message,
        status: true,
      });
      router.push({ path: `/Workflow/${data.project}/${res.data.workflow_id}` });
    } catch (error) {
      let data = error.response
        ? JSON.parse(error.response.data)
        : error.message
        ? error.message
        : error;
      dispatch("notify", { message: data.message, status: false });
    }
    commit("loaded");
  },
  async createWorkflowIteration({ commit, state, dispatch }, workflowDetails) {
    commit("load");
    try {
      const data = state.template;
      data.template_source = state.template.source;
      data.template_name = state.template.name;
      data.workflow_iteration = "" + workflowDetails.iteration;
      data.project = workflowDetails.project;
      data.queue = workflowDetails.queue
      // set the template's iteration value to the workflow iteration value
      if(state.template.params.find((p) => p.name === "iteration")){
        state.template.params.find((p) => p.name === "iteration").value = data.workflow_iteration;
      }
      else{
        state.template.params.push({name:'iteration',value:data.workflow_iteration})
      }
      if(!state.template.queue){
        state.template.queue=data.queue
      }
      console.log(state.template)
      // make a new line for each list value
      state.template.params
        .filter((p) => p.type === "list")
        .forEach((p) => (p.value = p.viewValue.split("\n")));
      state.template.params
        .filter((p) => p.value === undefined)
        .forEach((p) => (p.value = p.default));
      const res = await axios.post(`${API_URL}/create_workflow`, data, await authHeader());
      dispatch("notify", {
        message: "Workflow has been created: " + res.data.message,
        status: true,
      });
      router.push({ path: `/Workflow/${data.project}/${res.data.workflow_id}` });
    } catch (error) {
      console.log(error);
      let data = error.response
        ? JSON.parse(error.response.data)
        : error.message
        ? error.message
        : error;
      dispatch("notify", { message: data.message, status: false });
    }
    commit("loaded");
  },
};

export const getters = {
  getTemplate: (state) => state.template,
  templateLoading: (state) => state.templateLoading,
};
