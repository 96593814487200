import { createRouter, createWebHistory } from 'vue-router';
import { verifyToken } from '../middleware/verify-token';
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue'),
    alias: '/login'
  },
  {
    path: '/Workflow/:projectName?/:workflowId?',
    name: 'Workflow',
    component: () => import('../views/Workflow.vue'),
    props: true
  },
  {
    path: '/WorkflowConfig/:workflowName?/:workflowURL?',
    name: 'WorkflowConfig',
    component: () => import('../components/WorkflowDetails/WorkflowConfig.vue'),
    props: true
  },
  {
    path: '/jobs/:projectName?/:workflowId?/:status?/:step?',
    name: 'Jobs',
    component: () => import('../components/WorkflowDetails/Jobs.vue'),
    props: true
  },
  {
    path: '/Workflows/:projectName?',
    name: 'Workflows',
    component: () => import('../views/Workflows.vue'),
    props: true
  },
  {
    path: '/JobDetails/:projectName?/:workflowId?/:step?/:jobId?',
    name: 'JobDetails',
    component: () => import('../views/Job.vue'),
    props: true
  },
  {
    path: '/WorkflowJobsSearch/:projectName?/:workflowId?/',
    name: 'WorkflowJobsSearch',
    component: () => import('../components/WorkflowDetails/WorkflowJobsSearch.vue'),
    props: true
  },
  {
    path: '/WorkflowHistory/:projectName?/:workflowName?/:workflowId?/',
    name: 'WorkflowHistory',
    component: () => import('../components/WorkflowDetails/WorkflowHistory.vue'),
    props: true
  },
  {
    path: '/WorkflowAdvancedConfig/:projectName?/:workflowName?/:workflowId?/',
    name: 'WorkflowAdvancedConfig',
    component: () => import('../components/WorkflowDetails/WorkflowAdvancedConfig.vue'),
    props: true
  },
  {
    path: '/EditWorkflow/',
    name: 'EditWorkflow',
    component: () => import('../components/WorkflowDetails/EditWorkflow.vue'),
  },
  {
    path: '/NewWorkflowIteration/:projectName?/:iteration?/:workflowName?/:workflowId?/:templateName?/:workflowQueue?',
    name: 'NewWorkflowIteration',
    component: () => import('../components/WorkflowDetails/NewWorkflowIteration.vue'),
    props: true
  },
  {
    path: '/Queues',
    name: "Queues",
    component: () => import('../views/Queues.vue')
  },
  {
    path: '/Template/:source?/:template_name?/:projectName?',
    name: 'Template',
    component: () => import('../views/Template.vue'),
    props: true
  },
  {
    path: '/NewWorkflow/:projectName?',
    name: 'NewWorkflow',
    component: () => import('../views/Templates.vue'),
    props: true
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('../views/Projects.vue'),
  },
  {
    path: '/Help',
    name: 'Help',
    component: () => import('../views/Help.vue')
  },
  {
    path: '/Portal/:projectName?',
    name: 'Portal',
    component: () => import('../components/WorkflowDetails/Portal.vue'),
    props: true
  },
  {
    path: '/JobConfig/:project?/:workflow_id?/:step?/:job_id?/:status?',
    name: 'JobConfig',
    component: () => import('../components/JobConfigDetails/JobConfig.vue'),
    props: true
  },
  {
    path: '/StepConfig/:project?/:workflow_id?/:step?/:workflowStatus?',
    name: 'StepConfig',
    component: () => import('../components/StepConfigDetails/StepConfig.vue'),
    props: true
  },
  { path: '/:pathMatch(.*)*', component: () => import('../components/Generics/404.vue') },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  if(!authRequired){
    return next()
  }

  let result = await verifyToken()
  if(result){
    store.dispatch('checkAndRefreshToken')
  }
  else{
    store.dispatch('storeRedirectURL')
    return next('/login')
  }

  next();
})

export default router;
